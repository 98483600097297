<template>
  <div class="Score">
    <div style="border-bottom:1px solid #f2f2f2">
      <el-radio-group v-model="is_wenli" size="medium" @change="getData()" style="margin-left: 75px;">
        <el-radio-button label="1">文科</el-radio-button>
        <el-radio-button label="2">理科</el-radio-button>
      </el-radio-group>
    </div>
    <div id="main"></div>
  </div>
</template>

<script>
//time: 2020/12/7
export default {
  name: "Score",
  data() {
    return {
      is_wenli: 1,
      wenOne: [521, 547, 527, 558, 556, 536, 547, 516, 517, 513],
      wenTwo: [428, 465, 445, 466, 465, 447, 436, 389, 458, 455],
      wenThree: [185, 185, 190, 200, 180, 160, 200, 180, 180, 180],
      liOne: [511, 514, 509, 518, 544, 502, 499, 484, 523, 529],
      liTwo: [396, 409, 405, 400, 418, 385, 374, 342, 447, 458],
      liThree: [185, 185, 190, 200, 180, 160, 200, 180, 180, 180],
    };
  },
  mounted() {
    this.createEcharts(this.wenOne, this.wenTwo, this.wenThree);
  },
  methods: {
    getData() {
      if (this.is_wenli == 1) {
        this.createEcharts(this.wenOne, this.wenTwo, this.wenThree);
      } else {
        this.createEcharts(this.liOne, this.liTwo, this.liThree);
      }
    },
    createEcharts(one, two, three) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById("main"));
      let option = {
        title: {
          text: "河南省普通高校招生录取控制线走势图",
          x: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        legend: {
          data: ["本科一批", "本科二批", "高职高专批"],
          y: "bottom",
          borderWidth: "2px",
        },
        xAxis: {
          type: "category",
          data: [2024,2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015],
        },
        yAxis: {},
        series: [
          {
            name: "本科一批",
            type: "line",
            data: one,
          },
          {
            name: "本科二批",
            type: "line",
            data: two,
          },
          {
            name: "高职高专批",
            type: "line",
            data: three,
          },
        ],
        color: ["blue", "orange", "red"],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.Score {
  margin-bottom: 80px;

  #main {
    width: 100%;
    margin-top: 50px;
    min-height: 300px;
  }
}
</style>