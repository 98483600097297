<template>
  <div class="provinceLine">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
        <el-breadcrumb-item>智能填报</el-breadcrumb-item>
        <el-breadcrumb-item>省控线</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <Score></Score>
      <div style="border-bottom: 1px solid #f4f4f4">
        <el-radio-group v-model="year" size="medium" @change="getData()" style="margin-left: 75px">
          <el-radio-button label="2024" />
          <el-radio-button label="2023" />
          <el-radio-button label="2022" />
          <el-radio-button label="2021" />
          <el-radio-button label="2020" />
          <el-radio-button label="2019" />
          <el-radio-button label="2018" />
        </el-radio-group>
      </div>
      <div v-if="list">
        <div v-loading="loading" style="padding: 20px 77px" v-if="year < 2024">
          <div>
            <p class="title_score">{{ year }}年普通高校招生录取控制分数线</p>
            <table v-if="list.city_score" class="table_score">
              <tr class="title_table">
                <td rowspan="2" class="font_2">类别</td>
                <td rowspan="2" colspan="2">录取批次</td>
                <td colspan="4">文化线分数（分）</td>
                <td rowspan="2" colspan="3">专业分数线</td>
              </tr>
              <tr class="title_table">
                <td colspan="2">文科</td>
                <td colspan="2">理科</td>
              </tr>
              <tr>
                <td rowspan="4" class="font_3">普通类</td>
                <td colspan="2">本科一批</td>
                <td colspan="2">{{ list.city_score[0].score }}</td>
                <td colspan="2">{{ list.city_score[1].score }}</td>
                <td colspan="3">--</td>
              </tr>
              <tr>
                <td colspan="2">本科二批</td>
                <td colspan="2">{{ list.city_score[2].score }}</td>
                <td colspan="2">{{ list.city_score[3].score }}</td>
                <td colspan="3">--</td>
              </tr>
              <tr>
                <td colspan="2">高职高专批</td>
                <td colspan="2">{{ list.city_score[4].score }}</td>
                <td colspan="2">{{ list.city_score[5].score }}</td>
                <td colspan="3">--</td>
              </tr>
              <tr>
                <td colspan="9">
                  备注：国家专项批和地方专项批执行本科一批分数线，专科提前批执行高职高专批分数线
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="font_3">体育类</td>
                <td colspan="2">本科</td>
                <td colspan="2">{{ list.sport_score[0].curture_score }}</td>
                <td colspan="2">{{ list.sport_score[2].curture_score }}</td>
                <td colspan="3">{{ list.sport_score[0].major_score }}</td>
              </tr>
              <tr>
                <td colspan="2">专科</td>
                <td colspan="2">{{ list.sport_score[1].curture_score }}</td>
                <td colspan="2">{{ list.sport_score[3].curture_score }}</td>
                <td colspan="3">{{ list.sport_score[3].major_score }}</td>
              </tr>
              <tr class="font_3">
                <td rowspan="11">艺术类</td>
                <td colspan="2">专业考试类别</td>
                <td>本科A段</td>
                <td colspan="2">本科B段</td>
                <td>专科</td>
                <td>本科A段</td>
                <td>本科B段</td>
                <td>专科</td>
              </tr>
              <tr>
                <td colspan="2">招生学校单独组织的专业考试类</td>
                <td v-if="year == 2023" colspan="4">339</td>
                <td v-if="year == 2022" colspan="4">325</td>
                <td v-if="year == 2021" colspan="4">337</td>
                <td v-if="year == 2020" colspan="4">350</td>
                <td v-if="year == 2019" colspan="4">320</td>
                <td v-if="year == 2018" colspan="4">328</td>
                <td v-if="year == 2017" colspan="4">296</td>
                <td colspan="3">
                  达到省统考专业合格线并达到学校规定的校考合格线
                </td>
              </tr>
              <tr>
                <td rowspan="8">全省同意组织的专业考试类</td>
                <td>{{ list.art_score[0].type }}</td>
                <td class="font_1">{{ list.art_score[0].curture_score }}</td>
                <td class="font_1" colspan="2">
                  {{ list.art_score[1].curture_score }}
                </td>
                <td class="font_1">{{ list.art_score[2].curture_score }}</td>
                <td class="font_1">{{ list.art_score[0].major_score }}</td>
                <td class="font_1">{{ list.art_score[1].major_score }}</td>
                <td class="font_1">{{ list.art_score[2].major_score }}</td>
              </tr>
              <tr>
                <td>{{ list.art_score[3].type }}</td>
                <td>{{ list.art_score[3].curture_score }}</td>
                <td colspan="2">{{ list.art_score[4].curture_score }}</td>
                <td>{{ list.art_score[5].curture_score }}</td>
                <td>{{ list.art_score[3].major_score }}</td>
                <td>{{ list.art_score[4].major_score }}</td>
                <td>{{ list.art_score[5].major_score }}</td>
              </tr>
              <tr>
                <td>{{ list.art_score[6].type }}</td>
                <td>{{ list.art_score[6].curture_score }}</td>
                <td colspan="2">{{ list.art_score[7].curture_score }}</td>
                <td>{{ list.art_score[8].curture_score }}</td>
                <td>{{ list.art_score[6].major_score }}</td>
                <td>{{ list.art_score[7].major_score }}</td>
                <td>{{ list.art_score[8].major_score }}</td>
              </tr>
              <tr>
                <td>{{ list.art_score[9].type }}</td>
                <td>{{ list.art_score[9].curture_score }}</td>
                <td colspan="2">{{ list.art_score[10].curture_score }}</td>
                <td>{{ list.art_score[11].curture_score }}</td>
                <td>{{ list.art_score[9].major_score }}</td>
                <td>{{ list.art_score[10].major_score }}</td>
                <td>{{ list.art_score[11].major_score }}</td>
              </tr>
              <tr>
                <td>{{ list.art_score[12].type }}</td>
                <td>{{ list.art_score[12].curture_score }}</td>
                <td colspan="2">{{ list.art_score[13].curture_score }}</td>
                <td>{{ list.art_score[14].curture_score }}</td>
                <td>{{ list.art_score[12].major_score }}</td>
                <td>{{ list.art_score[13].major_score }}</td>
                <td>{{ list.art_score[14].major_score }}</td>
              </tr>
              <tr>
                <td>{{ list.art_score[15].type }}</td>
                <td>{{ list.art_score[15].curture_score }}</td>
                <td colspan="2">{{ list.art_score[16].curture_score }}</td>
                <td>{{ list.art_score[17].curture_score }}</td>
                <td>{{ list.art_score[15].major_score }}</td>
                <td>{{ list.art_score[16].major_score }}</td>
                <td>{{ list.art_score[17].major_score }}</td>
              </tr>
              <tr>
                <td>{{ list.art_score[18].type }}</td>
                <td>{{ list.art_score[18].curture_score }}</td>
                <td colspan="2">{{ list.art_score[19].curture_score }}</td>
                <td>{{ list.art_score[20].curture_score }}</td>
                <td>{{ list.art_score[18].major_score }}</td>
                <td>{{ list.art_score[19].major_score }}</td>
                <td>{{ list.art_score[20].major_score }}</td>
              </tr>

              <tr v-if="year > '2019'">
                <td>{{ list.art_score[21].type }}</td>
                <td>{{ list.art_score[21].curture_score }}</td>
                <td colspan="2">{{ list.art_score[22].curture_score }}</td>
                <td>{{ list.art_score[23].curture_score }}</td>
                <td>{{ list.art_score[21].major_score }}</td>
                <td>{{ list.art_score[22].major_score }}</td>
                <td>{{ list.art_score[23].major_score }}</td>
              </tr>
              <tr>
                <td colspan="9">备注：艺术类文、理科执行相同分数线</td>
              </tr>
            </table>
          </div>
          <div style="margin-top: 60px">
            <p class="title_score">
              {{ year }}年河南省普通高校对口招生录取控制分数线
            </p>
            <table v-if="list.city_score" class="table_score">
              <tr class="title_table">
                <td>类别代号</td>
                <td>对口专业列表</td>
                <td>本科线</td>
                <td>专科线</td>
              </tr>
              <tr v-for="(item, i) in list.other_score" :key="i">
                <td>{{ item.code }}</td>
                <td>{{ item.type_name }}</td>
                <td>{{ item.score }}</td>
                <td>{{ item.major_score }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div v-loading="loading" style="padding: 20px 77px" v-if="year == 2024">
        <div>
          <p class="title_score">{{ year }}年普通高校招生录取控制分数线</p>
          <table v-if="list.city_score" class="table_score1">
            <tr class="title_table">
              <td rowspan="2" class="font_2">类别</td>
              <td colspan="2" rowspan="2">录取批次</td>
              <td colspan="4">文化线分数（分）</td>
              <td colspan="4" rowspan="2">专业分数线</td>
            </tr>
            <tr class="title_table">
              <td colspan="2">文科</td>
              <td colspan="2">理科</td>
            </tr>
            <tr>
              <td rowspan="3" class="font_3">普通类</td>
              <td colspan="2">本科一批</td>
              <td colspan="2">{{ list.city_score[0].score }}</td>
              <td colspan="2">{{ list.city_score[1].score }}</td>
              <td colspan="4">--</td>
            </tr>
            <tr>
              <td colspan="2">本科二批</td>
              <td colspan="2">{{ list.city_score[2].score }}</td>
              <td colspan="2">{{ list.city_score[3].score }}</td>
              <td colspan="4">--</td>
            </tr>
            <tr>
              <td colspan="2">高职高专批</td>
              <td colspan="2">{{ list.city_score[4].score }}</td>
              <td colspan="2">{{ list.city_score[5].score }}</td>
              <td colspan="4">--</td>
            </tr>

            <tr>
              <td rowspan="2" class="font_3">体育类</td>
              <td colspan="2">本科</td>
              <td colspan="2">{{ list.sport_score[0].curture_score }}</td>
              <td colspan="2">{{ list.sport_score[2].curture_score }}</td>
              <td colspan="3">{{ list.sport_score[0].major_score }}</td>
            </tr>
            <tr>
              <td colspan="2">专科</td>
              <td colspan="2">{{ list.sport_score[1].curture_score }}</td>
              <td colspan="2">{{ list.sport_score[3].curture_score }}</td>
              <td colspan="3">{{ list.sport_score[3].major_score }}</td>
            </tr>

            <tr class="font_3">
              <td rowspan="11">艺术类</td>
              <td colspan="2">专业考试类别</td>
              <td colspan="2">本科</td>
              <td colspan="2">专科</td>
              <td colspan="2">本科</td>
              <td colspan="2">专科</td>
            </tr>
            <tr class="font_3">
              <td colspan="1" rowspan="3">音乐类</td>
              <td colspan="1">音乐表演（声乐）</td>
              <td colspan="2">285</td>
              <td colspan="2">165</td>
              <td colspan="2">215</td>
              <td colspan="2">185</td>
            </tr>
            <tr class="font_3">
              <td colspan="1">音乐表演（器乐）</td>
              <td colspan="2">285</td>
              <td colspan="2">165</td>
              <td colspan="2">210</td>
              <td colspan="2">185</td>
            </tr>
            <tr class="font_3">
              <td colspan="1">音乐教育</td>
              <td colspan="2">311</td>
              <td colspan="2">165</td>
              <td colspan="2">200</td>
              <td colspan="2">185</td>
            </tr>
            <tr class="font_3">
              <td colspan="2">舞蹈类</td>
              <td colspan="2">265</td>
              <td colspan="2">165</td>
              <td colspan="2">225</td>
              <td colspan="2">205</td>
            </tr>

            <tr class="font_3">
              <td colspan="1" rowspan="3">表（导）演类</td>
              <td colspan="1">戏剧影视表演方向</td>
              <td colspan="2">312</td>
              <td colspan="2">165</td>
              <td colspan="2">233</td>
              <td colspan="2">200</td>
            </tr>
            <tr class="font_3">
              <td colspan="1">服装表演方向</td>
              <td colspan="2">265</td>
              <td colspan="2">165</td>
              <td colspan="2">200</td>
              <td colspan="2">180</td>
            </tr>
            <tr class="font_3">
              <td colspan="1">戏剧影视导演方向</td>
              <td colspan="2">347</td>
              <td colspan="2">165</td>
              <td colspan="2">217</td>
              <td colspan="2">200</td>
            </tr>

            <tr class="font_3">
              <td colspan="2">播音与主持类</td>
              <td colspan="2">347</td>
              <td colspan="2">165</td>
              <td colspan="2">214</td>
              <td colspan="2">200</td>
            </tr>

            <tr class="font_3">
              <td colspan="2">美术与设计类</td>
              <td colspan="2">300</td>
              <td colspan="2">165</td>
              <td colspan="2">200</td>
              <td colspan="2">180</td>
            </tr>
            <tr class="font_3">
              <td colspan="2">书法类</td>
              <td colspan="2">300</td>
              <td colspan="2">165</td>
              <td colspan="2">205</td>
              <td colspan="2">180</td>
            </tr>
          </table>
        </div>
        <div style="margin-top: 60px">
          <p class="title_score">
            {{ year }}年河南省普通高校对口招生录取控制分数线
          </p>
          <table v-if="list.city_score" class="table_score">
            <tr class="title_table">
              <td>类别代号</td>
              <td>对口专业列表</td>
              <td>本科线</td>
              <td>专科线</td>
            </tr>
            <tr v-for="(item, i) in list.other_score" :key="i">
              <td>{{ item.code }}</td>
              <td>{{ item.type_name }}</td>
              <td>{{ item.score }}</td>
              <td>{{ item.major_score }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Score from "./components/Score";
export default {
  name: "ProvinceLine",
  components: {
    Score,
  },
  data() {
    return {
      list: [],
      year: 2024,
      loading: true,
      test: false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.list = []
      this.loading = true;
      let that = this
      this.$fecth
        .get("city_score/getData", { params: { city: 16, year: this.year } })
        .then((res) => {
          this.list = res;
          that.loading = false
        });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  width: 1100px;
  margin: 0 auto;
}

.provinceLine {
  background: #f4f4f4;

  .content {
    width: 1100px;
    background: #fff;
    padding: 30px 0;
    margin: 0 auto;
  }

  .title_score {
    color: #464645;
    font-size: 20px;
    margin: 20px 0;
    padding: 0 20px;
    text-align: center;
  }

  .table_score {
    width: 100%;

    .title_table {
      td {
        background: #1498ea !important;
        color: #ffffff;
      }
    }

    td {
      text-align: center;
      border-collapse: collapse;
      background: #f4f4f4;
      border: 1px solid white;
      padding: 10px 7px;
    }

    .font_1 {
      width: 90px;
    }

    .font_2 {
      width: 30px;
    }

    .font_3 {
      font-weight: bold !important;
    }
  }

  .table_score1 {
    width: 100%;
    border-collapse: collapse;

    .title_table {
      td {
        background: #1498ea !important;
        color: #ffffff;
      }
    }

    td {
      text-align: center;
      width: 50px;
      background: #f4f4f4;
      border: 5px solid white;
      padding: 10px 7px;
    }

    .font_11 {
      width: 90px;
    }

    .font_21 {
      width: 30px;
    }

    .font_31 {
      font-weight: bold !important;
    }
  }
}
</style>